#app{
  height: 100%;
}

.root{
  height: 100%;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
