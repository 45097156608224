@import '~antd/lib/style/themes/default.less';
.register-stp1 {
  display: flex;
  justify-content: center;
  height: 100%;
  background: #f3f3f3;
  .reg-form{

    padding: 36px;
    box-shadow: 0 0 100px rgba(0,0,0,.08);
    background: #fff;
    .reghead {
      font-size: 16px;
      color: #1890ff;
      margin-bottom: 20px;
      text-align: center;
    }
    .submit {
      width: 50%;
    }
    .loginLink {
      float: right;
      line-height: @btn-height-lg;
    }
  }
}
  .register-stp2 {
    display: flex;
    justify-content: center;
    height: 100%;
    background: #f3f3f3;
    .reg-form2{
      padding: 36px;
      width: 400px;
      box-shadow: 0 0 100px rgba(0,0,0,.08);
      background: #fff;
      .getCaptcha{
        display: block;
        width: 100%;
      }
      .submit2 {
        width: 100%;
      }
    }
  }
