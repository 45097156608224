@import "variables";
@import "global";
@import "scroll";
@import "table";
@import "login";
@import "icons";
@import "button";
@import "modal";
@import "menu";
@import "banner";
@import "card";
@import "img";
@import "utils-text";
@import "utils-color";
@import "utils-size";
@import "utils-border";
@import "utils-spacing";
#root{
  height: 100%;
}
.ant-layout{
  height: 100%;
  .logo{
    height: 64px;
    background: #333;
    border-radius: 2px;
    justify-content: center;
    margin: 6px;
    img{
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }
    h1{
      font-size: 20px;
      color: white;
      margin: 0 0 0 12px;
    }
  }
  .ant-layout-sider-collapsed{
    .anticon{
      font-size: 16px;
      margin-left: 8px;
    }
    .nav-text{
      display: none;
    }
    .ant-menu-submenu-vertical > .ant-menu-submenu-title:after{
      display: none;
    }
    .ant-menu-dark:not(.ant-menu-inline) .ant-menu-submenu-open {
      color: inherit;
    }
  }
  p {
    margin: 10px 0 10px 0;
  }
}
.ant-layout-base .ant-layout-container {
  margin: 24px 16px;
  background: #fff;
  padding: 24px;
}

.ant-layout-base .ant-layout-tab { // tab模式时的样式
  margin: 8px 8px;
  background: #fff;
}
.gutter-example{
  .ant-row{
    //margin-left: 0 !important;
    //margin-right: 0 !important;
    >div{
      background: transparent;
      border: 0;
    }
  }
}
.gutter-box {
  padding: 5px 0;
}

:global {
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    font-size: 12px !important;
  }
  .ant-card-head-title {
    font-size: 14px !important;
  }
}
